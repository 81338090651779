
<template>
  <section>
    <section class="search-container">
      <a-form layout="inline" class="m-t-14" :form="form" @submit="submitForm">
        <a-form-item label="经手人">
          <a-input
            autocomplete="off"
            v-decorator="['operator']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="领用人">
          <a-input
            autocomplete="off"
            v-decorator="['receiver']"
            placeholder="请输入"
          />
        </a-form-item>
        <!-- <a-form-item label="经手人">
          <a-input placeholder="请输入" />
        </a-form-item> -->

        <a-form-item label="领取时间">
          <a-range-picker v-decorator="['time']" style="width: 224px">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-item>
        <a-form-item label="仓库">
          <a-select
            style="width: 120px"
            placeholder="请选择"
            @change="handleChange"
            v-decorator="['warehouseId']"
          >
            <a-select-option
              v-for="item in warehouseData"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit"
            ><a-icon type="search" />查询</a-button
          >
          <a-button @click="reset"><a-icon type="reload" />重置</a-button>
        </a-form-item>
      </a-form>
      <a-row class="right-action-panel m-t-14">
        <a-button
          @click="exprotFile('materialOutStorageExport', { params })"
          class="item"
          ><a-icon type="export" />导出</a-button
        >
        <router-link to="/mms/inout/warehouseOut/addWarehouseOut">
          <a-button type="primary" class="item">新增领取</a-button>
        </router-link>
      </a-row>
    </section>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        :columns="list.columns"
        @change="changePageNum"
        :pagination="{
          showQuickJumper: false,
          current: list.pageNum,
          total: list.total,
        }"
      >
        <template slot="operation" slot-scope="list, text">
          <router-link
            :to="'/mms/inout/warehouseOut/warehouseOutDetail?id=' + text.id"
          >
            <a style="color: #529aff">查看</a>
          </router-link>
          <!-- <a style="color:#529aff">查看</a> -->
          <!-- /mms/inout/warehouseIn/warehouseOutDetail
          <a-divider style="color:#222" type="vertical" />
          <a style="color:#529aff">删除</a> -->
        </template>
      </a-table>
    </section>
  </section>
</template>

<script>
import { exprotFile } from "@/until/unit";
export default {
  data() {
    return {
      params: {},
      current: 1,
      endTime: "",
      operator: "",
      receiver: "",
      startTime: "",
      warehouseId: "",
      warehouseData: [], //仓库下拉列表
      form: this.$form.createForm(this),
      list: {
        total: 0,
        data: [],
        pageNum: 1,
        columns: [
          { title: "序号", dataIndex: "ind", align: "center",ellipsis: true,  },
          { title: "出库单据编号", dataIndex: "code", align: "center" ,ellipsis: true, },
          { title: "领取日期", dataIndex: "outTime", align: "center" ,ellipsis: true, },
          { title: "领取人", dataIndex: "receiver", align: "center",ellipsis: true,  },
          { title: "存放仓库", dataIndex: "warehouseName", align: "center",ellipsis: true,  },
          { title: "经手人", dataIndex: "operator", align: "center",ellipsis: true,  },
          {
            title: "操作",
            dataIndex: "operation",
            align: "center",
            scopedSlots: { customRender: "operation" },
          },
        ],
      },
    };
  },
  methods: {
    exprotFile,
    // 查询
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.form.getFieldsValue();
      this.list.pageNum = 1;
      this.operator = formVal.operator;
      this.receiver = formVal.receiver;
      this.startTime = formVal.time ? formVal.time[0].format("YYYY-MM-DD") : "";
      this.endTime = formVal.time ? formVal.time[1].format("YYYY-MM-DD") : "";
      this.warehouseId = formVal.warehouseId;
      this.getOutStorageistList();
    },
    // 重置
    reset() {
      this.operator = "";
      this.receiver = "";
      this.startTime = "";
      this.endTime = "";
      this.warehouseId = "";
      this.list.pageNum = 1;
      // 重置
      this.form.resetFields();
      this.getOutStorageistList();
    },
    // 仓库下拉列表
    warehouse() {
      this.$api.warehouseData().then((res) => {
        if (res.code == "200") {
          this.warehouseData = res.data ? res.data : [];
        }
      });
    },
    // 获取仓库id
    handleChange(val) {
      this.warehouseId = val;
    },
    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.getOutStorageistList();
    },
    // 列表
    getOutStorageistList() {
      this.params = {
        current: this.list.pageNum,
        endTime: this.endTime,
        operator: this.operator,
        receiver: this.receiver,
        startTime: this.startTime,
        warehouseId: this.warehouseId,
      };
      this.$api.getOutStorageistList(this.params).then((res) => {
        if (res.code == "200") {
          console.log(res.data.records);

          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.key = index + 1;
              item.ind = index + 1;
              item.outTime=item.outTime.split(' ')[0]
            });
          }
          this.list.data = res.data.records;
          this.list.total = res.data.total;
        }
      });
    },
  },
  created() {
    this.getOutStorageistList(); //出库管理
    this.warehouse(); //仓库下拉别表
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100% !important;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
</style>